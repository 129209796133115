/**
 *
 * Account Components Index
 *
 */
import { Grid, withStyles } from '@material-ui/core';
import { Calendar } from 'Calendar';
import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'react-extras';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Fullstory from 'components/Fullstory';
import {
  FULLSTORY_STAGING_HOST_ENABLED,
  fullstorySnippetString,
} from 'components/Fullstory/constants';
import AIInterviewersManagement from 'containers/AIInterviewersManagement/Loadable';
import { AIJobsDirectoryCareerSite } from 'containers/AIJobsDirectoryCareerSite';
import { AIWorkflowBuilder } from 'containers/AIWorkflowBuilder';
import AnalyticsDashboard from 'containers/AnalyticsDashboard/Loadable';
import ApplicantsV2 from 'containers/ApplicantsV2/Loadable';
import {
  makeSelectCustomerAttributesEnabled,
  makeSelectIsSuperAdmin,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import BulkActionQueue from 'containers/BulkActionQueue';
import { BulkLocations } from 'containers/BulkLocations';
import { ChatAgent } from 'containers/ChatAgent';
import { ChatAgentUpsell } from 'containers/ChatAgent/Upsell';
import { Chatbot } from 'containers/Chatbot';
import { Upsell } from 'containers/Chatbot/Upsell';
import CustomerAttributes from 'containers/CustomerAttributes/Loadable';
import LegacyDashboardHome from 'containers/DashboardHome/Loadable';
import GlobalDrawer from 'containers/Drawer/Loadable';
import DuplicateApplicantManagement from 'containers/DuplicateApplicantManagement/Loadable';
import EditApplicant from 'containers/EditApplicant/Loadable';
import GlobalPopup from 'containers/GlobalPopup';
import LogicJumps from 'containers/LogicJumps/Loadable';
import { ManageNotificationPreference } from 'containers/ManageNotificationPreference';
import MasterApplicantsView from 'containers/MasterApplicantsView/Loadable';
import Messenger from 'containers/Messenger/Loadable';
import { OpeningApproval } from 'containers/OpeningApproval';
import Openings from 'containers/Openings/Loadable';
import PaymentsWrapper from 'containers/Payments/Loadable';
import PostToIndeedWrapper from 'containers/PostToIndeed/Loadable';
import Sourcing from 'containers/Sourcing/Loadable';
import SourcingDashboard from 'containers/SourcingDashboard/Loadable';
import SourcingPurchaseNew from 'containers/SourcingPurchaseNew/Loadable';
import UserGroupsManagement from 'containers/UserGroupsManagement/Loadable';
import { Webhooks } from 'containers/Webhooks';
import WhatsAppMessageTemplate from 'containers/WhatsAppMessageTemplate/Loadable';
import DistributeApplicantsRuleStage from 'containers/WorkflowEditor/components/DistributeApplicantsRuleStage';
import WorkflowEditor from 'containers/WorkflowEditor/Loadable';
import WorkflowTable from 'containers/WorkflowTable/Loadable';

import { CreateEvent } from '../../../Calendar/CreateEvent';
import { EditEvent } from '../../../Calendar/EditEvent';
import styles from './styles';

class AccountIndex extends React.PureComponent {
  state = {};

  render() {
    const {
      account,
      classes,
      isSuperAdmin,
      customAttributesEnabled,
      match: { path },
      mobileDrawerOpen,
      toggleMobileDrawer,
      handleCloseMobileDrawer,
      location: { pathname },
    } = this.props;
    const { whoami } = this.state;
    const isAnalytics = pathname.indexOf('analytics_dashboard') > -1;
    const isPayments = pathname.indexOf('payments') > -1;
    const showStandardLayout = !isAnalytics && !isPayments;
    const { policies } = whoami;

    return (
      <>
        <GlobalPopup />

        <BulkActionQueue />

        <div className={classes.root}>
          {whoami.email &&
            (!isSuperAdmin || FULLSTORY_STAGING_HOST_ENABLED) && (
              <>
                <Helmet>
                  <script>{fullstorySnippetString}</script>
                </Helmet>
                <Fullstory whoami={whoami} />
              </>
            )}
          {showStandardLayout && (
            <GlobalDrawer
              account={account}
              open={mobileDrawerOpen}
              toggle={toggleMobileDrawer}
              onDrawerItemSelect={handleCloseMobileDrawer}
            />
          )}
          <Grid
            container
            justify="center"
            className={classNames(classes.content)}
          >
            <Switch>
              <Route
                path={`${path}/applicants/:externalApplicantId/edit`}
                component={EditApplicant}
              />
              <Route
                exact
                path={`${path}/applicants`}
                component={MasterApplicantsView}
              />

              {/* Sourcing Urls */}
              {/* Indeed Campaign Creation */}
              <Route
                path={`${path}/jobs/:jobId/post_to_indeed/new`}
                component={PostToIndeedWrapper}
              />
              {/* Regular Boost Creation - we keep this route for boost creation from openings - `Get Applicants`) */}
              <Route
                path={`${path}/jobs/:jobId/sourcing/new`}
                component={SourcingPurchaseNew}
              />
              {/* Sourcing Navigation (global)  */}
              <Route path={`${path}/sourcing`} component={Sourcing} />
              {/* Only used for sales demo. This is not the actual  Sourcing dashboard used by customers */}
              <Route
                path={`${path}/sourcing_dashboard`}
                component={SourcingDashboard}
              />
              {/* Sourcing Urls END */}

              <Route
                path={`${path}/duplicate_applicant_settings`}
                component={DuplicateApplicantManagement}
              />
              {whoami.whats_app_enabled && (
                <Route
                  path={`${path}/whatsapp_message_templates`}
                  component={WhatsAppMessageTemplate}
                />
              )}

              {whoami.opening_approval_enabled && (
                <Route
                  path={`${path}/opening_approvals`}
                  component={OpeningApproval}
                />
              )}

              {/* Chatbot URLs */}
              {whoami.fountain_ai_upsell_enabled && (
                <Route path={`${path}/chatbot/upsell`} component={Upsell} />
              )}
              {(whoami.chatbot_admin_enabled ||
                whoami.fountain_ai_enabled ||
                whoami.fountain_ai_faq_enabled ||
                whoami.chatbot_review_enabled ||
                whoami.chatbot_automated_response_enabled) && (
                <Route path={`${path}/chatbot`} component={Chatbot} />
              )}
              {/* Chatbot URLs end */}

              <Route exact path={`${path}/openings`} component={Openings} />
              <Route
                exact
                path={`${path}/bulk_locations`}
                component={BulkLocations}
              />
              <Route
                path={`${path}/jobs/:jobId/v2/stages/:stageExternalId?`}
                component={ApplicantsV2}
              />
              <Route
                exact
                path={`${path}/analytics_dashboard/:dashboardId?`}
                component={AnalyticsDashboard}
              />
              <Route
                path={`${path}/brands/:brandSlug/stages/:stageId/logic`}
                component={LogicJumps}
              />
              <Route
                path={`${path}/:logicJumpOrigin/:stageId/logic`}
                component={LogicJumps}
              />
              <Route
                path={`${path}/brands/:brandSlug/locations/:locationId/jobs/:jobId/v2/stages/:stageExternalId?`}
                component={ApplicantsV2}
              />
              {policies.manage_user_groups && (
                <Route
                  path={`${path}/user_groups`}
                  component={UserGroupsManagement}
                />
              )}
              <Route
                path={`${path}/ai_interviewers`}
                component={AIInterviewersManagement}
              />
              <Route
                path={`${path}/jobs/:jobExternalId/stages/:stageExternalId/workflow`}
                component={DistributeApplicantsRuleStage}
              />
              <Route
                path={`${path}/brands/:brandSlug/payments`}
                component={PaymentsWrapper}
              />
              <Route path={`${path}/payments`} component={PaymentsWrapper} />

              {/* Messenger 2.0 Navigation */}
              <Route
                path={`${path}/messenger/location_groups/:locationGroupId/jobs/:jobId/applicants/:applicantId?`}
                component={Messenger}
              />
              <Route
                path={`${path}/messenger/applicants/:applicantId?`}
                component={Messenger}
              />
              <Route path={`${path}/messenger`} component={Messenger} />

              {/* WorkflowEditor 2.0 */}
              {whoami.workflow_editor_v2_enabled && (
                <Route
                  path={`${path}/openings/:funnelSlug/workflow/:stageSlug?`}
                  component={WorkflowEditor}
                />
              )}

              <Route path={`${path}/workflows`} component={WorkflowTable} />

              {customAttributesEnabled && (
                <Route
                  path={`${path}/openings/:funnelSlug/customer_attributes`}
                  component={CustomerAttributes}
                />
              )}

              <Route path={`${path}/schedule`} component={Calendar} />

              <Route path={`${path}/webhooks`} component={Webhooks} />

              {whoami.ai_workflow_builder_enabled && (
                <Route
                  path={`${path}/openings/:funnelSlug/ai_workflow_builder`}
                  component={AIWorkflowBuilder}
                />
              )}

              {whoami.calendar_event_creation_enabled && (
                <Route path={`${path}/calendar/new`} component={CreateEvent} />
              )}

              {whoami.calendar_event_creation_enabled && (
                <Route
                  path={`${path}/calendar/:eventExternalId/edit`}
                  component={EditEvent}
                />
              )}

              {whoami.cai_agent_enabled && (
                <Route path={`${path}/fountain_ai`} component={ChatAgent} />
              )}

              <Route
                path={`${path}/fountain_ai/upsell`}
                component={ChatAgentUpsell}
              />

              {whoami.fountain_ai_career_site_enabled && (
                <Route
                  path={`${path}/ai_jobs_directory_career_site`}
                  component={AIJobsDirectoryCareerSite}
                />
              )}

              {whoami.feature_flags['custom-user-notification'] && (
                <Route
                  path={`${path}/:notifiableType/:notifiableId/notification_preferences`}
                  component={ManageNotificationPreference}
                />
              )}

              {/* LEGACY route, but DO NOT REMOVE
                  This container is used only for Fountain Mobile App homepage (w/ Tasks) and Settings
                  Only accessible by GlobalDrawer in mobile app */}
              <Route component={LegacyDashboardHome} />
              {/* LEGACY route END */}
            </Switch>
          </Grid>
        </div>
      </>
    );
  }
}

// sigh https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
AccountIndex.getDerivedStateFromProps = props => ({
  whoami: props.whoami,
});

AccountIndex.propTypes = {
  account: PropTypes.object,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool,
  customAttributesEnabled: PropTypes.bool,
  mobileDrawerOpen: PropTypes.bool,
  toggleMobileDrawer: PropTypes.func,
  handleCloseMobileDrawer: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = () =>
  createStructuredSelector({
    whoami: makeSelectWhoami(),
    isSuperAdmin: makeSelectIsSuperAdmin(),
    customAttributesEnabled: makeSelectCustomerAttributesEnabled(),
  });

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withStyles(styles),
  withConnect,
)(AccountIndex);
